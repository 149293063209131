.bg-image-container {
  width: 100%;
  min-height: 900px;
  top: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.bg-image {
  width: max(100%, 1350px);
  position: absolute;
  z-index: -1;
}
.gray-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  background-color: rgba(3, 3, 3, 0.6);
}
.top-content-top {
  display: flex;
  flex-direction: column;
}
.homepage-top-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 900px;
  position: relative;
  justify-content: space-between;
  padding: 18rem 0 0 0rem;
  width: min(1550px, 95%);
  margin-inline: auto;
  h1 {
    font-size: clamp(2rem, 9vw, 4.5rem);
    font-weight: 800;
    line-height: clamp(1.75rem, 8vw, 4rem);
    color: var(--croonus-white);
    span {
      display: flex;

      span {
        display: inline-block;
      }
    }
  }
  .rect-1 {
    position: relative;
    top: 20px;
    width: 170px;
    height: 55px;
    margin-inline-end: 0.5rem;
  }
  .rect-2 {
    position: relative;
    top: 20px;
    width: 20px;
    height: 55px;
    margin-inline: 0.5rem;
  }
  p {
    color: var(--croonus-white);
    max-width: 500px;
    font-size: 1.25rem;
    line-height: 1.375rem;
    padding: 3rem 0;
  }
}
.read-more {
  height: 50px;
  font-weight: 500;
}

.homepage-top-content-buttons {
  display: flex;
}

.arrows {
  margin-inline-start: 1rem;
}
.read-more-arrow {
  padding: 0.25rem 0;
}

.value-of-quote-container {
  border-top-left-radius: 10px;
  background-color: var(--primary-color);
  // margin-top: auto;
  // margin-top: 13rem;
  color: var(--croonus-white);
  padding: 2rem 2.5rem 1rem 2.5rem;
  position: relative;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    background-color: var(--secondary-color);
    height: 6px;
    width: 100%;
    border-top-left-radius: 10px;
  }
  &::after {
    position: absolute;
    content: '';
    top: 0;
    right: -200px;
    width: 200px;
    height: 100%;
    background-color: var(--primary-color);
    border-top: 6px solid var(--secondary-color);
  }
}
.voq-value {
  color: var(--secondary-color);
  text-transform: uppercase;
  font-weight: 500;
}
.voq-text {
  font-weight: 800;
  font-size: clamp(1.5rem, 2vw, 2.25rem);
  .rect-3 {
    width: 70px;
    height: 22px;
    margin-inline-end: 0.5rem;
    top: 2px;
    border-radius: 5px;
  }
}
.home-page-truck-1-img {
  position: absolute;
  z-index: 1;
  bottom: 15px;
  right: -20%;
  transition: all 2s ease-in-out;
}

.home-page-middle {
  position: relative;
}
.profile-image-and-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  h4 {
    margin: 0;
  }
}

.single-profile {
  p {
    text-align: justify;
  }
}

.home-page-partners-left {
  padding: 3rem 10rem 2rem 0;
  position: relative;
  p {
    padding: 2rem 0;
    font-size: 1rem;
    font-weight: 450;
    line-height: 1.375rem;
  }
  h3 {
    font-size: 1.5rem;
    font-weight: 650;
    margin: unset;
  }
  span {
    font-style: italic;
  }
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: calc(100% + 150px);
    bottom: 0;
    left: 0;
    z-index: -1;
    border-bottom-right-radius: 10px;
    background-color: var(--secondary-color);
  }
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: calc(100% + 150px);
    bottom: 0;
    left: -200px;
    z-index: -1;
    border-bottom-right-radius: 10px;
    background-color: var(--secondary-color);
  }
}

.circle-profile-image {
  height: 120px;
  width: 120px;
  overflow: hidden;
  border-radius: 100%;
  aspect-ratio: 1;
  border: 4px solid var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    display: block;
    min-width: 100%;
    min-height: 100%;
  }
}

.primary-sub-heading-info {
  display: block;
  font-size: 1.0625rem;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--primary-color);
}
.sub-heading {
  font-size: clamp(1.5rem, 2vw, 2.25rem);
  font-weight: 700;
}
.sub-rect-1 {
  width: 70px;
  border-radius: 5px;
  margin-inline-end: 0.5rem;
  top: 6px;
}
.home-page-partners-right {
  padding: 2rem 0rem 0rem 2rem;
  & > span {
    padding-left: 1rem;
    display: block;
  }
}
.partners-container {
  padding-right: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 2rem;
  & > div {
    margin-inline: 1rem;
    width: 28%;
    @media (max-width: 575.98px) {
      height: 100px;
    }
  }
}

.benefits-container {
  padding: 6rem 0 0rem 0;
  margin-inline: auto;
  width: min(1550px, 95%);
  .benefit-col {
    padding: 0 2rem;
  }
  margin-bottom: 2rem;
  .sub-heading {
    margin-bottom: 2rem;
    padding-left: 2rem;
    text-align: left;
    .sub-rect-1 {
      height: 18px;
      top: 3px;
    }
    @media (min-width: 575.98px) {
      display: none;
    }
  }
}
.benefit-rect {
  width: 28px;
  border-radius: 5px;
  height: 16px;
  top: 2px;
  margin-inline-end: 0.5rem;
}
.deliver-bespoke-container {
  width: min(1550px, 95%);
  margin-inline: auto;
}
.deliver-bespoke-left {
  padding: 3rem 0rem 3rem 0rem;
}
.deliver-bespoke-rect {
  width: 70px;
  height: 25px;
  border-radius: 5px;
  top: 3px;
  margin-inline-end: 0.5rem;
}
.deliver-bespoke-button {
  height: 60px;
  width: 450px;
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  span {
    text-transform: uppercase;
  }
  :last-child {
    transform: translateX(250%);
    transition: all 0.3s ease-in-out;
  }
  &::after {
    display: none;
  }
  &:hover {
    background-color: var(--primary-color);
    span {
      color: var(--croonus-white) !important;
    }
    :last-child {
      transform: translateX(0);
      transition: all 0.2s ease-in-out;
    }
  }
}
.deliver-bespoke-button-active {
  background-color: var(--primary-color);
  span {
    color: var(--croonus-white) !important;
  }
  :last-child {
    transform: translateX(0);
    transition: all 0.2s ease-in-out;
  }
}
.deliver-bespoke-buttons-container {
  margin-top: 4rem;
}
.deliver-bespoke-container {
  height: 700px;
}
.deliver-bespoke-slick {
  & > div {
    overflow: visible;
  }
}
.deliver-bespoke-right {
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  .deliver-bespoke-slider {
    transition: all 0.4s ease-in-out;
    width: 100%;
    display: flex;
    @media (max-width: 1399.98px) {
      height: 900px;
    }
    @media (max-width: 1199.98px) {
      height: 700px;
    }
    @media (max-width: 900.98px) {
      height: 600px;
    }
    @media (max-width: 700.98px) {
      height: 500px;
    }
    @media (max-width: 575.98px) {
      height: 600px;
    }
    @media (max-width: 450.98px) {
      height: 550px;
    }
    @media (max-width: 400.98px) {
      height: 520px;
    }
    .deliver-bespoke-slider-item {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
  position: relative;
  .deliver-bespoke-right-img {
    height: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    img {
      width: 90%;
      transform: translateY(30%) translateX(50px);
      @media (max-width: 1199.98px) {
        transform: translateY(30%);
      }
      @media (max-width: 991.98px) {
        transform: translateY(50%);
      }
      @media (max-width: 767.98px) {
        transform: translateY(70%);
      }
      @media (max-width: 575.98px) {
        transform: translateY(130%);
      }
      @media (max-width: 500.98px) {
        transform: translateY(160%);
      }
      @media (max-width: 420.98px) {
        transform: translateY(170%);
      }
      @media (max-width: 380.98px) {
        transform: translateY(190%);
      }
    }
  }
}
.top-heading-list {
  margin-left: 1rem;
  margin: 1rem 0 1rem 1rem;
  list-style: disc;
  font-weight: 500;
  font-size: clamp(0.875rem, 2vw, 1.125rem);
  color: var(--croonus-white);
}

.deliver-bespoke-right-floater {
  position: absolute;
  display: flex;
  align-items: center;
  img {
    height: 60px;
  }
  top: 4rem;
  right: 1rem;
  padding: 2rem;
  height: fit-content;
  background-color: var(--croonus-white);
  // transform: translateX(-50%) translateY(-50%);
  border-radius: 10px;
  box-shadow: 0 0 10px 10px rgba(150, 150, 150, 0.1);
  @media (max-width: 575.98px) {
    width: 90% !important;
    flex-direction: column;
    top: 1rem;
    img {
      height: 40px;
    }
  }
}
.view-all-cs {
  position: absolute;
  bottom: -2rem;
  right: 1rem;
  display: flex;
  margin-top: 3rem;
  font-size: clamp(0.75rem, 3.2vw, 1rem);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.view-all-cs-rect {
  margin-inline-end: 0.5rem;
  top: 5px;
  width: 10px;
  height: 12px;
  border-radius: 3px;
}
.trailer-specs-col {
  padding: 0 5rem;
  @media (max-width: 767.98px) {
    padding: 0 1rem;
  }
  position: relative;
  @media (min-width: 576px) {
    &:first-child::after {
      right: 0;
      top: 0%;
      height: 100%;
      background-color: var(--secondary-color);
      content: '';
      width: 2px;
      position: absolute;
    }
  }
}

.trailer-specs-span {
  display: flex;
  width: 100%;
  padding: 0.125rem 0;
  justify-content: space-between;
  font-weight: 600;
  span {
    &:first-child {
      padding-right: 0.5rem;
    }
    font-weight: 400;
  }
  position: relative;
  &:first-child::before {
    position: absolute;
    content: '';
    bottom: -1px;
    width: 100%;
    height: 2px;
    left: 0;
    background-color: var(--croonus-lightMedGray);
  }
}
.deliver-bespoke-buttons-container {
  span {
    text-align: left;
  }
}
.numbers-container {
  padding: 1rem 0 5rem 0;
  width: min(1550px, 95%);
  margin-inline: auto;
}

.numbers-container-heading {
  width: fit-content;
  margin: 0 auto;
  :first-child {
    text-align: center;
  }

  :last-child {
    text-align: left;
  }
  .numbers-rect {
    width: 120px;
    margin-inline-end: 0.5rem;
    height: 25px;
    border-radius: 5px;
    top: 2px;
  }
  @media (max-width: 767.98px) {
    margin: unset;
    span {
      display: inline-block;
    }
    :first-child {
      text-align: left;
    }
  }
}
.home-page-partners {
  width: min(1550px, 95%);
  margin-inline: auto;
}
.map-container {
  position: relative;
  height: 700px;
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-45%);
    max-width: 100%;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  &::after {
    content: '';
    position: absolute;
    top: 55%;
    width: 100%;
    left: 0;
    height: 3px;
    background-color: var(--secondary-color);
  }
}

.map-benefits-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.map-benefits-col-cloud {
  width: 180px;
  height: 140px;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 6rem;
  background-color: var(--secondary-color);
  img {
    height: 60px;
    filter: brightness(0) invert(1);
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(35%) rotate(45deg);
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background-color: var(--secondary-color);
  }
}
.more-references {
  color: var(--primary-color);
  font-weight: 500;
  text-align: right;
  display: block;
  margin-inline-start: auto;
  margin-inline-end: 3.5rem;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: var(--primary-color);
  }
  @media (max-width: 575.98px) {
    margin-inline-end: 1.5rem;
  }
}
.map-benefits-span-1 {
  font-size: 3rem;
  font-weight: 700;
  color: var(--primary-color);
  line-height: 3rem;
}
.map-benefits-span-2 {
  font-size: 1.5rem;
}

@media (max-width: 1500px) {
  .home-page-truck-1-img {
    transform: scale(90%) translateY(5%) translateX(5%);
  }
}
.deliver-bespoke-right-img {
  img {
    width: 90% !important;
  }
}
@media (max-width: 1399.98px) {
  .home-page-truck-1-img {
    transform: scale(80%) translateY(10%) translateX(12%);
  }
  .benefits-container {
    .benefit-col {
      padding: 0 8rem;
      margin-bottom: 4rem;
    }
  }
  .deliver-bespoke-container {
    height: unset;
    .deliver-bespoke-right-img {
      & > img {
        width: 90%;
      }
    }
    .deliver-bespoke-right {
      .deliver-bespoke-right-floater {
        transform: translateX(-50%) translateY(25%);
        left: 50%;
        width: fit-content;
      }
      // margin-bottom: 10rem;
    }
    .deliver-bespoke-left {
      display: flex;
      flex-direction: column;
      align-items: center;

      .deliver-bespoke-buttons-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        button {
          width: min(800px, 90vw);
        }
      }
    }
  }
}
@media (max-width: 1299.98px) {
  .home-page-truck-1-img {
    transform: scale(70%) translateY(15%) translateX(21%);
  }
}
@media (max-width: 1198.98px) {
  .map-container {
    &::after {
      display: none;
    }
    height: unset;
  }
  .map-benefits-col-cloud {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .benefits-container {
    .benefit-col {
      padding: 0 4rem;
      margin-bottom: 4rem;
    }
  }
  .numbers-container-heading {
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
  }

  .home-page-truck-1-img {
    transform: scale(50%) translateY(25%) translateX(40.8%);
  }
  .home-page-partners-right {
    //padding:2rem 0 2rem 2rem
  }

  .home-page-partners-left {
    &::before {
      width: calc(100vw + 300px);
      left: -100px;
    }
  }
}
@media (max-width: 991.98px) {
  .deliver-bespoke-left {
    padding: 0rem 0rem 0rem 0rem;
  }
  .value-of-quote-container {
    &::after {
      display: none;
    }
    &::before {
      border-top-right-radius: 10px;
    }
  }
  .benefits-container {
    padding: 2rem 0;
    .benefit-col {
      padding: 0 1rem !important;
      margin-bottom: 2rem !important;
    }
  }
  .value-of-quote-container {
    margin-top: 18rem;
  }
  .homepage-top-content {
    height: unset;
    min-height: unset;
  }
  .deliver-bespoke-container {
    margin-bottom: 5rem;

    margin-top: 3.5rem !important;
  }
  .homepage-top-content {
    padding-top: 10rem;
    .rect-1 {
      top: 15px;
      height: 40px;
      width: 100px;
    }
    .rect-2 {
      top: 15px;
      height: 40px;
    }
  }
  .bg-image-container {
    height: unset;
    min-height: 800px;
  }
  .home-page-truck-1-img {
    //display: none;
  }
}

@media (max-width: 767.98px) {
  .primary-sub-heading-info {
    font-size: 0.75rem;
    align-self: flex-start;
    padding-left: 2rem;
  }
  .sub-heading {
    font-size: 1.25rem;
    align-self: flex-start;
    padding-left: 2rem;
  }
  .map-container {
    .map-benefits-col-cloud {
      width: 120px;
      height: 110px;
      img {
        height: 40px;
      }
    }
    .map-benefits-span-1 {
      font-size: 1.5rem;
    }
    .map-benefits-span-2 {
      font-size: 1rem !important;
      text-align: center;
    }
  }
  .home-page-partners-left {
    padding: 4rem 0 4rem 0;
    p {
      font-size: 1rem;
    }
    h3 {
      font-size: 1.125rem;
    }
    span {
      font-size: 0.875rem;
    }
  }
  .homepage-top-content-buttons {
    flex-direction: column;
    button {
      width: fit-content;
    }
    & > span {
      margin-top: 1rem;
      width: fit-content;
      margin-left: 0;
    }
  }
  .benefits-container {
    .benefit-col {
      padding: 0 0.5rem;
      margin-bottom: 4rem;
    }
  }
  .homepage-top-content {
    padding-top: 10rem;
    .rect-1 {
      top: 15px;
      height: 30px;
      width: 80px;
    }
    .rect-2 {
      top: 15px;
      height: 30px;
    }
  }
  .home-page-partners-right {
    padding: 2rem 0;
    span {
      text-align: left;
      padding-left: 2rem;
    }
    & > div {
      justify-content: center;
    }
    .partners-container {
      & > div {
        width: 35%;
      }
    }
  }
}
@media (max-width: 575.98px) {
  .circle-profile-image {
    margin-inline: auto;
  }
  .value-of-quote-container {
    margin-top: 15rem;
  }
  .home-page-truck-1-img {
    transform: scale(35%) translateY(50%) translateX(80%);
  }
  .benefits-container {
    padding: 0;
    .benefit-col {
      padding: 0 2rem;
      margin-bottom: 4rem;
    }
  }
  .map-container {
    img {
      transform: translateX(-50%) translateY(-45%) scale(1.5);
    }
  }
  .home-page-partners-left {
    padding: 2rem 2.5rem;
  }
  .homepage-top-content {
    padding-top: 10rem;
    .rect-1 {
      top: 10px;
      height: 25px;
      width: 80px;
    }
    .rect-2 {
      top: 10px;
      height: 25px;
    }
  }
}

@media (max-width: 759px) {
  .home-page-truck-1-img {
    bottom: 9.5rem;
    right: 0;
    transform: scale(60%) translateY(45%) translateX(70%);
  }
}
@media (max-width: 560px) {
  .home-page-truck-1-img {
    transform: scale(50%) translateY(60%) translateX(80%);
  }
}
@media (max-width: 420px) {
  .home-page-truck-1-img {
    bottom: 12.5rem;
    transform: scale(38%) translateY(100%) translateX(103%);
  }
}
