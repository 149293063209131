
.container {
    margin: 1rem 0;
  padding: 0rem 0rem 0 0rem;
  width:100%;
}

.footer-top-left-heading {
  width: 100%;
  font-size: 1.25rem;
  font-weight: 650;
}
.footer-rect {
  top: 2px;
  width: 30px;
  height: 20px;
  border-radius: 5px;
  margin-inline-end: 0.5rem;
}
.btn-accordion {
  background-color: transparent;
  border: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 1.125rem;
}
.btn-accordion {
    font-weight: 500;
}
.card {
  border: none;
  background-color: transparent;
}
.card-header {
  button {
    padding: unset;
    h2 {
      color: white;
      text-align: left;
    }
    svg {
      display:none;
    }
  }
  padding: unset;
  background-color: transparent;
  border: none;
}
.card-body {
  position: relative;
  padding: 0 0rem;
}

