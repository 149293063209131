.container {
  padding: 5rem 0 1rem 0;
  width:min(1550px, 95%);
  margin-inline: auto;
}
.top-buttons {
  h2 {
    font-weight: 700;
  }
  display: flex;
  & > button {
    &:last-child {
      margin-left: auto;
    }
  }
}
.top-buttons-rect {
  width: 60px;
  height: 25px;
  top: 3px;
  border-radius: 5px;
  margin-inline-end: 0.5rem;
}

.paragraphs {
    margin-top:5rem;
}