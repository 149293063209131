.container {
    padding: 5rem 0 3rem 0;
    width:min(1550px, 95%);
    margin-inline: auto;
}
.sub-heading {
    font-weight: 700;
    font-size: 2rem;
    padding-left: 1rem;
    display:block;
}
.partner-rect {
    top: 2px;
    margin-inline-end: 0.5rem;
    width: 70px;
    height:20px;
    border-radius: 5px;
}
.partners-container {
    margin-top:3rem;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
}
.partner {
    width:30%;
    height: 180px;
    margin-inline: 1rem;
}
.read-more {
    margin-top: 5rem;
    height:60px;
    font-weight: 600;
    margin-left: auto;
    margin-right: 1.5rem;
}

@media(max-width: 767.98px) {
    .partner {
        width: 38%;
    }
}