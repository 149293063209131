.top-form-container {
  width: min(1550px, 95%);
  margin-inline: auto;
}
.top-form {
  padding: 2rem 1rem 2rem 1rem;
  border-radius: 10px;
  background-color: var(--croonus-white);
  box-shadow: 0 0 10px 10px rgba(60, 60, 60, 0.1);
}
.top-form-left {
  padding: 1rem 3rem;
  .float-input-div-container {
    margin-bottom: 2rem;
    padding: 0 0.5rem;
    height: 65px;
    div {
      height: 100%;
    }
    input {
      height: 100%;
    }
  }
}
.top-form-right {
  padding: 0rem 1rem 0rem 0;
  position: relative;
  z-index: 0;
}

.custom-select {
  width: 100%;
  position: relative;
  & > select {
    display: none;
  }
}

.top-form-right-content {
  border-radius: 10px;
  padding: 2rem 1rem 0 1rem;
  overflow: hidden;
  position: relative;
  h5 {
    font-size: 1rem;
    color: var(--croonus-white);
    font-weight: 600;
  }
  p {
    font-size: 0.875rem;
    line-height: 1rem;
    padding: 1rem 0;
    color: var(--croonus-white);
  }
  .road-img {
    position: absolute;
    width: 100%;
    top: -100px;
    left: 0;
    z-index: -1;
  }
  .yellow-overlay {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--secondary-color);
    opacity: 0.6;
  }
}

.appointment-button {
  height: 40px;
  width: min(300px, 100%);
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  & > span:first-child {
    padding-right: 2rem;
  }
  :last-child {
    padding: 0.125rem 0;
  }
}

.form-send-request {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  text-decoration: none;
}
.value-of-quote-container {
  border-top-left-radius: 10px;
  background-color: var(--primary-color);
  margin-bottom: 3rem;

  color: var(--croonus-white);
  padding: 2rem 2.5rem 1rem 2.5rem;
  position: relative;
  overflow: hidden;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    background-color: var(--secondary-color);
    height: 6px;
    width: 100%;
  }
}
.voq-value {
  color: var(--secondary-color);
  text-transform: uppercase;
  font-weight: 500;
}
.voq-text {
  font-weight: 800;
  font-size: clamp(1.5rem, 2vw, 2.25rem);
  .rect-3 {
    width: 70px;
    height: 22px;
    margin-inline-end: 0.5rem;
    top: 2px;
    border-radius: 5px;
  }
}

.top-right-modal {
  padding: 1rem 3.5rem;
}

@media (max-width: 1399.98px) {
  .top-form-right {
    padding: 0 3.5rem;
    span {
      font-weight: 600;
    }
    p {
      font-weight: 500;
    }
  }
}
@media (max-width: 1198.98px) {
  .modal {
    .float-input-div-container {
      order: 1;
    }
    .order {
      order: 2;
    }
  }
}
@media (max-width: 1050px) {
  .top-form-left {
    padding: 0 0rem;
  }
  .top-form-right {
    padding: 0 0.5rem;
  }
}

@media (max-width: 991.98px) {
  .float-input-div-container {
    order: 1;
  }
  .order {
    order: 2;
  }
}
