.container {
  padding: 5rem 0 1rem 0;
  width:min(1550px, 95%);
  margin-inline: auto;
  @media (max-width:575.98px) {
    padding: 4rem 1rem 1rem 1rem;
  }
  h4 {
    margin-top: 1rem;
    position:relative;
    .title-divider {
      position:absolute;
      top:-100px;
    }
  }
  h5 {
    margin: 1.5rem 0;
  }
  h6 {
    color:var(--croonus-mediumGray);
    margin-top:1rem;
    margin-bottom: 1rem;
  }
  .paragraphs {
    margin-bottom:4rem;
    ul {
      list-style-type: '- ';
      padding-left: 1rem;
      
    }
  }
  .cookie-paragraphs {
    b {
      font-weight: 600;
    }
    ul {
      list-style-type: disc;
      padding-left: 1.25rem;
      li {
        padding-bottom: 0.5rem;
      }
    }}
}

h2 {
  font-weight: 700;
}
.terms-rect {
    width:70px;
    height:20px;
    border-radius: 5px;
    margin-inline-end: 0.5rem;
}
.content {
    margin: 3rem 0;
}
h4{
    font-size: 1.375rem;
    font-weight: 600;
    margin-bottom: 2rem;
}
.terms-small-rect {
    width:12px;
    top:1px;
    height:20px;
    margin-inline-end: 0.5rem;
    border-radius: 5px;
}
