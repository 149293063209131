.header-container {
  padding: 0rem 0;
  width: min(1550px, 95%);
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  position: absolute;
}
.mail-phone {
  display:block;
  height:50px;
  color: var(--croonus-white);
  font-size: 1rem;
  img {
    height:100%;
    padding:0 1rem;
    filter: brightness(0) invert(1);
  }
  padding: 0.75rem 0;
}
.mail-phone-mobile {
  display:block;
  height:50px;
  color: var(--croonus-black);
  font-size: 1rem;
  img {
    height:100%;
    padding:0 1rem;
    filter: brightness(0);
  }
  padding: 0.75rem 0;
}
.mail-phone-mobile-container {
  display:flex;
  justify-content: space-between;
  margin: 1.5rem 0 2rem 0;
}
.mobile-header-list {
  li {
    padding: 1rem;
    border-bottom: 1px solid var(--primary-color);
    font-weight: 500;
  }
  a {
    text-decoration: none;
    color: var(--primary-color);
  }
}
.nav-button-mobile {
  height: 50px;
  margin: 0.75rem 0;
  width:100%; 
  font-weight: 500;
  &>span {
    &>span {
      padding: 0.125rem 0;
    }
  }
}
.header-container-sticky {
  padding: 0rem 0;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  top: 0;
  span {
    color: var(--croonus-black);
  }
  .request-quote {
    // opacity: 0;
    // pointer-events: none;
    span {
      &::before {
        left:-59px;
      }
    }
  }
  .nav-links-container {
    ul {
      li {
        &:hover {
          background-color: black;
        }
        a {
          color: var(--croonus-white);
          transition: all 0.2s ease-in-out;
          &:hover {
            color: var(--coronus-black);
            background-color: var(--croonus-white);
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
          }
        }
        .active-link {
          color: var(--croonus-black);
          background-color: var(--croonus-white);
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
          transition: transform 0.4s ease-in-out;
        }
      }
    }
  }
  background-color: var(--primary-color);
  position: fixed;
}
.sticky-padding {
  margin-inline: auto;
  width: min(1550px, 95%);
}
.globe-span {
  height: 100%;
  padding-right: 0.5rem;
  img {
    height: 100%;
  }
}
.header-top {
  height: 40px;
  display: flex;
  justify-content: space-between;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
    height: 3px;
    width: calc(100% - 10px);
    background-color: var(--primary-color);
  }
}
.language-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  &::before {
    content: "";
    position: absolute;
    left: -1px;
    top: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border: 5px solid var(--primary-color);
    border-top-color: transparent;
    border-bottom-left-radius: 10px;
  }
  &::after {
    transition: all 0s !important;
  }
  &:hover {
    &::after {
      border: 0px solid transparent;
      border-radius: 0px;
    }
  }
}

.header-bottom {
  height: 60px;
  display: flex;
  justify-content: space-between;
}

.header-bottom-img-container {
  height: 100%;
  cursor: pointer;
  padding: 0.5rem 0;
}
.request-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  height: 100%;
  span {
    font-weight: 600;
  }
  &::after {
    border-top-left-radius: 0px;
  }
}
.request-quote {
  height:100%;
}
.nav-links-container {
  ul {
    margin: unset;
    display: flex;
    li {
      a {
        display: block;
        padding: 1rem 0.5rem 0.5rem 0.5rem;
        font-weight: 600;
        text-decoration: none;
        color: var(--croonus-white);
      }
      transition: all 0.3s ease-in-out;
      .active-link {
        background-color: var(--primary-color);
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      &:hover {
        background-color: var(--primary-color);
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        transition: all 0.2s ease-in-out;
      }
    }
  }
}
.top-nav-links {
  display: flex;
  align-items: center;
  height:100%;
  a {
    display: flex;
    height: 100%;
    text-decoration: none;
    color: var(--croonus-white);
    position: relative;
    &:hover {
      text-decoration: underline;
    }
    padding: 0.375rem 1rem;
    img {
      height: 100%;
      filter: brightness(0) invert(1);
    }
    span {
      padding-left: 0.5rem;
    }
    &:last-child {
      padding-right: 0;
    }
    &:not(:last-child)::after {
      content: "";
      position: absolute;
      top: 20%;
      height: 60%;
      right: 0;
      width: 1px;
      background-color: var(--secondary-color);
    }
  }
}
.mobile-header-div {
  display: none;
}
@media (max-width: 1399.98px) {
.header-container{
  &:after {
    transform: translateX(-100%);
    width:100vw;
    background-color: white;
    content:'';
    position: absolute;
    left:-35px;
    top:0;
    height:100%;
    z-index: 3;
    transition: all 0.4s ease-in-out;
  }
}
.header-container-active {
  &:after {
    transform: translateX(0);
    transition: all 0.4s ease-in-out;
    width:100vw;
    background-color: white;
    content:'';
    position: absolute;
    left:-35px;
    top:0;
    height:100%;
  }
}
  .nav-links-container {
    display: none;
  }
  .request-quote {
    display: none;
  }
  .nav-icon {
    position: absolute;
    z-index: 4;
    span {
      background-color: var(--croonus-white);
    }
  }

  .nav-icon-active {
    span {
      background-color: var(--croonus-black);
    }
  }
  .mobile-header-div-active {
    transform: translateX(0%);
  }
  .mobile-header-div-deactive {
    transform: translateX(-100%);
  }
  
  .mobile-header-div {
    display: block;
    height: 100vh;
    padding: 4rem 1.375rem 1rem 1.375rem;
    z-index: 3;
    width: 100vw;
    position: fixed;
    left: 0;
    background-color: var(--croonus-white);
    transition: transform 0.4s ease-in-out;
    overflow-y: auto;
  }
  .header-bottom {
    justify-content: space-between;
    align-items: center;
  }
}
.header-container-sticky {
  .request-quote {
    .request-button {
      background-color: var(--croonus-white);
      span {
        color: var(--croonus-black);
      }
    }
  }
  .mobile-header-div-active {
    transform: translateX(0%);
  }
  .mobile-header-div-deactive {
    transform: translateX(-100%);
  }
  .sticky-padding {
    position: relative;
    z-index: 3;
    background-color: var(--primary-color);
    &::after {
      position:absolute;
      content:'';
      width:200px;
      height:100%;
      right:-150px;
      background-color: var(--primary-color);
      top:0;
    }
    &::before {
      position:absolute;
      content:'';
      width:200px;
      height:100%;
      left:-150px;
      background-color: var(--primary-color);
      top:0;
      z-index: -1;
    }
  }
}

@media (max-width: 575.98px) {
  .header-bottom-img-container {
    padding: 0.75rem 0;
  }
  
  .nav-icon-active {
    top: 0;
    span {
      background-color: white;
    }
  }
}