.footer-bottom {
  display: flex;
  height: 60px;
  padding: 0 0;
  width: 100%;
  justify-content: space-between;
  background-color: var(--primary-color-darker);
  align-items: center;
}
.footer-container {
  background-color: var(--primary-color);
}
.footer-bottom-content {
  width: min(1550px, 95%);
  margin-inline: auto;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer-bottom-text {
  margin: unset;
  color: var(--croonus-white);
  font-weight: 500;
  a {
    color: var(--secondary-color);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
.footer-bottom-icons {
  height: 100%;
  display: flex;
}

.footer-bottom-icon-container {
  height: 100%;
  padding: 1rem 0;
  background-color: var(--primary-color);
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  img {
    filter: invert(92%) sepia(0%) saturate(141%) hue-rotate(224deg)
      brightness(82%) contrast(91%);
  }
  &:hover {
    background-color: var(--secondary-color);
    img {
      filter: brightness(0) invert(1);
    }
  }
}

.footer-top {
  overflow: hidden;
  background-color: var(--primary-color);
  width: min(1730px, 97.5%);
  margin-inline-start: auto;
}
.footer-top-left {
  padding: 4rem 0 1rem 0rem;

  color: var(--croonus-white);
  position: relative;
  background-color: var(--primary-color);
  z-index: 2;
  &::after {
    border: 570px solid transparent;
    border-top-color: var(--primary-color);
    content: "";
    position: absolute;
    right: -575px;
    top: 0;
    width: 0;
    height: 0;
    z-index: -1;
    pointer-events: none;
  }
  &::before {
    border: 570px solid transparent;
    border-top-color: var(--secondary-color);
    content: "";
    position: absolute;
    right: -580px;
    top: 0;
    width: 0;
    height: 0;
    z-index: -2;
    pointer-events: none;
  }
}
.footer-top-left-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0.5rem;
  & > img {
    margin-bottom: 1rem;
  }
}
.form-send-request-arrow {
  height:100%;
  img {
    height:40px;
  }
}
.footer-top-left-col-middle {
  padding: 0 4rem;
}
.footer-top-about-firm {
  padding: 1.5rem 0 2rem 0;
  line-height: 1.25rem;
}
.footer-top-about-firm-2 {
  span {
    display: inline-block;
  }
}
.no-arrow-dropdown {
  display: none;
  .information-list {
    display:none;
  }
}
.footer-top-about-firm-3 {
  span {
    display: inline-block;
  }
  padding: 1rem 0;
}
.footer-top-left-heading {
  width: 100%;
  font-size: 1.25rem;
  font-weight: 650;
}
.footer-rect {
  top: 2px;
  width: 30px;
  height: 20px;
  border-radius: 5px;
  margin-inline-end: 0.5rem;
}

.information-list {
  padding-top: 1.5rem;
  li {
    a {
      text-decoration: none;
      color: var(--croonus-white);
      transition: all 0.15s ease-in-out;
      &:hover {
        color: var(--secondary-color);
        transition: all 0.1s ease-in-out;
      }
    }
  }
}
.newsletter-input {
  background-color: transparent;
  width: 100%;
  height: 70px;
  input {
    height: 100%;
    background-color: transparent;
    color: var(--croonus-white);
    &:focus,
    &:focus-visible {
      border-color: var(--secondary-color);
    }
  }
  label {
    color: var(--croonus-white);
  }
  margin-bottom: 0.5rem;
}

.form-send-request {
  font-weight: 600;
  width: 100%;
  font-size: 1.125rem;
  height: 70px;
  display: flex;
  justify-content: space-between;
  :last-child {
    padding: 0.375rem 0;
  }
}
.footer-top-right {
  position: relative;
  & > {
    position: absolute;
    z-index: 100;
  }
}
.map-marker {
  img {
    transform: translateX(-50%) translateY(-100%);
  }
}

.footer-top-right-cloud {
  display: none;
  border-radius: 10px;
  box-shadow: 0 0 10px 10px rgba(60, 60, 06, 0.1);
  padding: 1.5rem 2rem;
  position: absolute;
  left: 0;
  transform: translateX(-105%) translateY(-60%);
  width: 190px;
  pointer-events: none;
  background-color: var(--croonus-white);

  transform: translateX(-120%) translateY(-85%);
  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--primary-color);
    margin-bottom: 1rem !important;
    span {
      width: 15px;
    }
  }
  p {
    font-weight: 400 !important;
    margin: unset;
    font-size: 0.75rem;
    line-height: 0.875rem;
    span {
      display: block;
    }
  }
  &::before {
    content: "";
    background-color: var(--croonus-white);
    position: absolute;
    width: 35px;
    height: 35px;
    top: 50%;
    right: 0;
    transform: translateX(30%) translateY(-50%) rotate(45deg);
    border-radius: 10px;
  }
}

.footer-top-right-cloud-active {
  display: block;
}

.footer-form {
  margin: 3rem 0;
}

@media (max-width: 1399.98px) {
  .footer-top-left {
    &::before,
    &::after {
      display: none;
    }
  }
  .footer-top {
    width: 100%;
    margin: unset;
    padding: 0 1rem;
  }
  .footer-top-right {
    height: 500px;
    margin-bottom: 2rem;
  }
}
@media (max-width: 991.98px) {
  .footer-top-left-col-middle {
    &>.footer-top-left-heading {
      display:none;
    }
    &>.information-list {
      display:none;
    }
  }
  .no-arrow-dropdown {
    display:block;
    .footer-top-left-heading {
      display: block;
    }
    .information-list {
      display: block;
    }
  }
  .footer-top {
    padding: 0 1rem;
  }
  .footer-top-left-col {
    align-items: flex-start;
    text-align: left;
  }
  .footer-top-left-col-middle {
    margin: 2rem 0 2rem 0.5rem;
    align-items: flex-start;
    text-align: left;
    padding: unset;
  }
  .footer-bottom {
    height: unset;
  }
  .footer-bottom-content {
    height: unset;
    display: flex;
    flex-direction: column;
    padding: 1rem 0.5rem;
    p {
      margin-bottom: 2rem;
      text-align: center;
      a {
        display: inline-block;
      }
    }
  }
  .footer-bottom-icons {
    height: 60px;
  }
}

@media (max-width: 575.98px) {
  .footer-top-right {
    margin-top: 2rem;
    height: 250px;
    padding: 0 0.5rem;
  }
}
