.container {
  width: 100%;
  &:hover {
    .post-image img {
      transform: scale(1.25);
      transition: all 0.3s ease-in-out;
    }
    .gray-overlay {
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }
  }
}
.gray-overlay {
  position: absolute;
  width: 100%;
  opacity: 0.3;
  background-color: black;
  z-index: 0;
  height: 100%;
  top: 0;
  left: 0;

  transition: all 0.4s ease-in-out;
}

.post-image {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  height: 300px;
  img {
    width: 100%;
    height: 100%;
    transition: all 0.4s ease-in-out;
  }
}
.read-more {
  height: 40px;
  font-weight: 500;
}
.read-more-arrow {
  padding: 0;
}
.floating-div-container {
  padding: 0 1rem;
}
.floating-rect {
  width: 15px;
  height: 20px;
  top: 2px;
  margin-inline-end: 0.5rem;
  border-radius: 5px;
}
.floating-div {
  padding: 1rem 2rem;
  position: relative;
  top: -3rem;
  border-radius: 10px;
  color: var(--croonus-white);
  background-color: var(--primary-color);
  h4 {
    margin-bottom: 0.5rem;
    height: 3rem;
    overflow: hidden;
  }
  p {
    font-weight: 500;
    font-size: 0.875rem;
    height: 2.5rem;
    overflow: hidden;
  }
}
