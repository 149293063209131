@import "~bootstrap/scss/bootstrap.scss";
@import "~react-toastify/dist/ReactToastify.css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;450;500;550;600;650;700&display=swap");

body {
  font-family: "Montserrat", sans-serif;
  background-color: var(--croonus-white);
  margin: 0;
  overflow-y: scroll;
  overflow: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  overflow: hidden;
  //TODO
  white-space: pre-line;
}
ul {
  list-style: none;
  padding: unset;
}

:root {
  /*     CUSTOMIZE      */
  --primary-color: #006480;
  --primary-color-darker: #005972;
  --secondary-color: #faa61a;
  --secondary-color-medium: #253141;
  --secondary-color-darker: #131921;
  --border-color: rgba(30, 30, 30, 0.1);
  --croonus-alertRed: #f34f5d;
  --croonus-alertGreen: #31b931;
  --croonus-scrollColor: #cfcfcf;
  /*   COMMON COLORS    */
  --croonus-black: #030303;
  --croonus-white: #fff;
  --croonus-lightGray: #f6f8f9;
  --croonus-lightMedGray: #d4d4d4;
  --croonus-mediumGray: #747579;
  --croonus-darkGray: #333;
  --croonus-darkBlue: #015c93;
  --croonus-lightBlue: #0073b6;
  --croonus-darkGreen: #008548;
  --croonus-lightGreen: #00a65a;
  --croonus-darkRed: #b13b2d;
  --croonus-lightRed: #dd4c39;
  --croonus-darkYellow: #bb950c;
  --croonus-lightYellow: #ffcb06;
}

/*---------------- COMMON STYLES START -----------------*/
.modal-lg {
  max-width: 95%;
  margin-inline: auto;
}
.row {
  --bs-gutter-x: 0;
}
.relative {
  position: relative;
  z-index: 10000;
}
.flex-row {
  display: flex;
}
.flex-start {
  justify-content: flex-start;
}
.opacity-0 {
  opacity: 0;
}
.pad-container-1600 {
  width: min(1600px, 100%);
  margin: 0 auto;
}
.container-1600 {
  width: min(1600px, 100%);
  margin: 0 auto;
}
.display-none {
  display: none;
}
.basic-input {
  outline: none;
  border: 1px solid var(--croonus-black);
  padding: 0.25rem 0.5rem;
  border-radius: 0;
}
.mandatory {
  color: var(--croonus-alertRed);
}
.rect-primary {
  border-radius: 10px;
  display: inline-block;
  width: 10px;
  position: relative;
  height: 30px;
  background-color: var(--primary-color);
}
.rect-secondary {
  display: inline-block;
  border-radius: 10px;
  position: relative;
  width: 10px;
  height: 30px;
  background-color: var(--secondary-color);
}
.slick-dots {
  bottom: -25px !important;
}
.previous-button {
  margin-inline-end: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  border: none;
  height: 70px;
  border-radius: 10px;
  background-color: var(--secondary-color);
  text-align: left;

  position: relative;
  &::after {
    content: "";
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border-radius: 10px;
    border: 3px solid var(--primary-color);
    position: absolute;
    top: -1px;
    left: -1px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    background-color: var(--croonus-white);
    &::after {
      content: "";
      opacity: 1;
      transition: all 0.2s ease-in-out;
    }
  }
  & > img {
    height: 80%;
    padding-right: 1rem;
  }
  .previous-button-span-left {
    background-color: var(--primary-color);
    border-radius: 10px;
    display: block;
    padding: 1rem;
    height: 100%;
    img {
      filter: brightness(0) invert(1);
      transform: rotate(180deg);
      height: 100%;
    }
  }
  .previous-button-span-right {
    width: 200px;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 1rem;
    span {
      display: block;
    }
    .previous-button-span-right-1 {
      font-size: 0.875rem;
      line-height: 0.9125rem;
      text-align: left;
      text-transform: uppercase;
    }
    .previous-button-span-right-2 {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 0.875rem;
      line-height: 0.9125rem;
    }
  }
}
.next-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  border: none;
  height: 70px;
  border-radius: 10px;
  background-color: var(--secondary-color);
  position: relative;
  &::after {
    content: "";
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border-radius: 10px;
    border: 3px solid var(--primary-color);
    position: absolute;
    top: -2px;
    left: -2px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    background-color: var(--croonus-white);
    &::after {
      content: "";
      opacity: 1;
      transition: all 0.2s ease-in-out;
    }
  }
  & > img {
    height: 80%;
    padding-left: 1rem;
  }
  .next-button-span-right {
    background-color: var(--primary-color);
    border-radius: 10px;
    display: block;
    padding: 1rem;
    height: 100%;
    img {
      filter: brightness(0) invert(1);
      height: 100%;
    }
  }
  .next-button-span-left {
    width: 200px;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 1rem;
    span {
      display: block;
    }
    .next-button-span-left-1 {
      font-size: 0.875rem;
      line-height: 0.9125rem;
      text-align: left;
      text-transform: uppercase;
    }
    .next-button-span-left-2 {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 0.875rem;
      line-height: 0.9125rem;
    }
  }
}
#VOQ {
  position: absolute;
  top: -140px;
}
.basic-search-button {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-color: var(--primary-color);
  border: none;
  border-radius: 10px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  outline: none;
  &:active {
    background-color: var(--primary-color-darker);
  }
  .basic-search-button-image {
    height: 100%;
    filter: brightness(0) invert(1);
  }
}
.button-primary {
  display: flex;
  position: relative;
  justify-content: space-between;
  background-color: var(--primary-color);
  color: var(--croonus-white);
  border-radius: 10px;
  padding: unset;
  border: none;
  overflow: hidden;
  :first-child {
    color: var(--croonus-white);
    transition: all 0.3s ease-in-out;
  }
  transition: all 0.3s ease-in-out;
  span {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  &::after {
    content: "";
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border-radius: 10px;
    border: 3px solid var(--secondary-color);
    position: absolute;
    top: -1px;
    left: -1px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    background-color: var(--croonus-white);
    transition: all 0.2s ease-in-out;
    :first-child {
      color: var(--croonus-black);
      transition: all 0.2s ease-in-out;
    }
    &::after {
      opacity: 1;
      transition: all 0.3s ease-in-out;
    }
  }
}
input[type="date"] {
  #picker {
  }
  &::-webkit-inner-spin-button {
    width: calc(100% - 2rem);
    position: absolute;
    transform: rotateY(180deg);
    right: 1rem;
  }
  &::-webkit-calendar-picker-indicator {
    width: calc(100% - 2rem);
    position: absolute;
    transform: rotateY(180deg);
    right: 1rem;
  }
}
.button-secondary {
  display: flex;
  justify-content: space-between;
  background-color: var(--croonus-white);
  color: var(--primary-color);
  border-radius: 10px;
  padding: unset;
  position: relative;
  border: none;
  overflow: hidden;
  :first-child {
    color: var(--primary-color);
    transition: all 0.3s ease-in-out;
  }
  transition: all 0.3s ease-in-out;
  span {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  &::after {
    content: "";
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border-radius: 13px;
    border: 3px solid var(--croonus-white);
    position: absolute;
    top: -2px;
    left: -2px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    background-color: var(--primary-color);
    transition: all 0.2s ease-in-out;
    :first-child {
      color: var(--croonus-white);
      transition: all 0.2s ease-in-out;
    }
    &::after {
      opacity: 1;
      transition: all 0.3s ease-in-out;
    }
  }
}
.button-span-1 {
  padding: 0.5rem 4rem 0.5rem 1rem;
}
.button-span-2 {
  background-color: var(--secondary-color);
  padding: 0.5rem 0;
  z-index: 1;
  position: relative;
}
.bs2-45 {
  &::before {
    content: "";
    position: absolute;
    border: 45px solid transparent;
    left: -60px;
    z-index: -1;
    border-right-color: var(--secondary-color);
    border-bottom-color: var(--secondary-color);
  }
}
.bs2-40 {
  &::before {
    content: "";
    position: absolute;
    border: 40px solid transparent;
    left: -60px;
    z-index: -1;
    border-right-color: var(--secondary-color);
    border-bottom-color: var(--secondary-color);
  }
}
.bs2-35 {
  &::before {
    content: "";
    position: absolute;
    border: 35px solid transparent;
    left: -60px;
    z-index: -1;
    border-right-color: var(--secondary-color);
    border-bottom-color: var(--secondary-color);
  }
}

.bs2-35-2 {
  &::before {
    content: "";
    position: absolute;
    border: 35px solid transparent;
    left: -70px;
    z-index: -1;
    border-right-color: var(--secondary-color);
    border-bottom-color: var(--secondary-color);
  }
}
.bs2-30 {
  &::before {
    content: "";
    position: absolute;
    border: 30px solid transparent;
    left: -60px;
    z-index: -1;
    border-right-color: var(--secondary-color);
    border-bottom-color: var(--secondary-color);
  }
}
.bs2-25 {
  &::before {
    content: "";
    position: absolute;
    border: 25px solid transparent;
    left: -60px;
    z-index: -1;
    border-right-color: var(--secondary-color);
    border-bottom-color: var(--secondary-color);
  }
}
.bs2-20 {
  &::before {
    content: "";
    position: absolute;
    border: 20px solid transparent;
    left: -60px;
    z-index: -1;
    border-right-color: var(--secondary-color);
    border-bottom-color: var(--secondary-color);
  }
}
.float-input-div {
  position: relative;
  label {
    position: absolute;
    top: 0.25rem;
    font-size: 1.0625rem;
    font-weight: 500;
    left: 0.5rem;
    color: var(--primary-color);
    opacity: 1;
  }
  input {
    font-size: 1.125rem;
    padding: 1.5rem 0.5rem 0.5rem 0.5rem;
    width: 100%;
    border-radius: 10px;
    border: 1px solid var(--croonus-lightMedGray);
    &:focus,
    &:focus-visible {
      outline: none;
      border: 1px solid var(--croonus-mediumGray);
    }
  }
  select {
    font-size: 1.125rem;
    padding: 1.5rem 0.5rem 0.5rem 0.5rem;
    width: 100%;
    border-radius: 10px;
    text-align: center;
    -webkit-appearance: none;
    border: 1px solid var(--croonus-lightMedGray);
    &:focus,
    &:focus-visible {
      outline: none;
      border: 1px solid var(--croonus-mediumGray);
    }
    color: var(--croonus-black);
    background-color: var(--croonus-white);
  }
  textarea {
    font-size: 1.125rem;
    padding: 1.5rem 0.5rem 0.5rem 0.5rem;
    width: 100%;
    border-radius: 10px;
    border: 1px solid var(--croonus-lightMedGray);
    &:focus,
    &:focus-visible {
      outline: none;
      border: 1px solid var(--croonus-mediumGray);
    }
    resize: none;
  }
}
.arrow-button {
  height: 50px;
  display: flex;
  border-radius: 10px;
  background-color: var(--primary-color);
}
.arrow-left-white {
  display: flex;
  background-color: transparent;
  border: none;
  align-items: center;
  height: 50px;
  padding: 0.5rem 0rem;
  margin-inline: 0.5rem;
  padding-left: 0.5rem;
  img {
    height: 100%;
    transform: rotate(180deg);
    filter: brightness(0) invert(1);
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    img {
      filter: invert(80%) sepia(25%) saturate(4796%) hue-rotate(345deg)
        brightness(99%) contrast(98%);
      transition: all 0.2s ease-in-out;
    }
  }
}
.arrow-right-white {
  display: flex;
  background-color: transparent;
  border: none;
  align-items: center;
  height: 50px;
  padding: 0.5rem 0rem;
  margin-inline: 0.5rem;
  padding-right: 0.5rem;
  img {
    height: 100%;
    filter: brightness(0) invert(1);
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    img {
      filter: invert(80%) sepia(25%) saturate(4796%) hue-rotate(345deg)
        brightness(99%) contrast(98%);
      transition: all 0.2s ease-in-out;
    }
  }
}
.arrow-left-black {
  display: flex;
  background-color: transparent;
  border: none;
  align-items: center;
  height: 50px;
  padding: 0.5rem 0rem;
  margin-inline: 0.5rem;
  padding-left: 0.5rem;
  img {
    height: 100%;
    transform: rotate(180deg);
    filter: brightness(0);
  }
}
.arrow-right-black {
  display: flex;
  background-color: transparent;
  border: none;
  align-items: center;
  height: 50px;
  padding: 0.5rem 0rem;
  margin-inline: 0.5rem;
  padding-right: 0.5rem;
  img {
    height: 100%;
    filter: brightness(0);
  }
}
.basic-radio {
  position: relative;
  &::after {
    content: "";
    display: none;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 50%;
    background-color: var(--primary-color);
    position: absolute;
  }
  &:checked {
    &::after {
      display: block;
    }
  }
}
.basic-link {
  color: var(--croonus-black);
  text-decoration: none;

  transition: all 0.1s ease-in;
  &:hover {
    color: var(--primary-color);

    transition: all 0.1s ease-in;
  }
}
.basic-link-white {
  color: var(--croonus-white);
  text-decoration: none;

  transition: all 0.1s ease-in;
  &:hover {
    color: var(--primary-color);

    transition: all 0.1s ease-in;
  }
}
.button-img {
  height: 100%;
  filter: brightness(0) invert(1);
}
.banner-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}
.banner-link {
  display: block;
  height: 100%;
  width: 100%;
}
.color-white {
  color: var(--croonus-white);
}
.color-primary {
  color: var(--primary-color);
}
.weight-500 {
  font-weight: 500;
}
.weight-600 {
  font-weight: 600;
}
.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}

.img-primary-color {
  filter: invert(15%) sepia(88%) saturate(3687%) hue-rotate(348deg)
    brightness(107%) contrast(98%);
}
.basic-checkbox-container {
  display: flex;
  align-items: center;
}
.form-check-input {
  border: none;
  margin-top: 0em;
  background-color: var(--croonus-lightGray);
}
.form-check-input:focus {
  box-shadow: none;
  outline: none;
  border: none;
}

.form-check-input:checked {
  background-color: var(--primary-color);
}

.sub-heading {
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-family: azo_sansmedium;
}

.regular-text {
  font-size: 1.375rem;
  text-align: center;
  margin: 0;
}

.bg-red {
  background-color: var(--croonus-darkRed);
}
.bg-blue {
  background-color: var(--croonus-darkBlue);
}
.bg-green {
  background-color: var(--croonus-alertGreen);
}

@media (max-width: 100em) {
  .pad-container-1600 {
    padding: 0 1rem;
  }
}
@media (max-width: 700px) {
  .previous-button {
    .previous-button-span-right {
      display: none;
    }
    & > img {
      display: none;
    }
  }
  .next-button {
    .next-button-span-left {
      display: none;
    }
    & > img {
      display: none;
    }
  }
}

.grecaptcha-badge {
  z-index: 99999;
  display: block !important;
}
/*---------------- COMMON STYLES END -------------------*/
