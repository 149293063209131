.container {
    position: relative;
    overflow: hidden;
    h2 {
        margin-top: 12rem;
        color: var(--secondary-color);
        text-align: center;
        font-weight: 700;
        font-size: 3rem;
        margin-bottom: 0;
    }
    .breadcrumb {
        width:fit-content;
        background-color: var(--primary-color);
        margin: 1rem auto;
        border-radius: 5px;
        margin-bottom: 3rem;
        span {
            padding: 0 1rem;
            position: relative;
            font-size: 0.875rem;
            font-weight: 500;
            a {
                color: white;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
            &:not(:last-child) {
                &::after {
                    content: '';
                    position: absolute;
                    right: 0;
                    height:80%;
                    top: 20%;
                    width:1px;
                    transform: rotate(20deg);
                    background-color: var(--croonus-white);
                }
            }
        }
        &::after {
            position: absolute;
            content: '';
            bottom: 0;
            left:50%;
            transform: translateX(-50%);
            height:5px;
            width:min(1550px, 95%);
            background-color: var(--secondary-color);
        }
    }
}
.background-image {
    position:absolute;
    top: -150px;
    width:100%;
    img {
        width:max(100%, 900px);
    }
    z-index: -1;
    pointer-events: none;
}
.gray-overlay{
    position:absolute;
    width:100%;
    height:100%;
    pointer-events: none;
    top:0;
    left:0;
    background-color: rgba(3,3,3,0.5);
    z-index: 1;
}
