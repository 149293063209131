.container {
  padding: 5rem 0 1rem 0;
  width: min(1550px, 95%);
  margin-inline: auto;
  overflow-x: hidden;
  @media (max-width: 575.98px) {
    p,
    h4,
    .bottom,
    .top-buttons {
      padding: 0 1.5rem;
    }
  }
}
.border-check-img-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  img {
    width: min(700px, 100%);
  }
}
.boat-img-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  img {
    width: min(630px, 100%);
  }
}
.custom-slider {
  display: flex;
  width: max-content;
  transform: translateX(0vw);
  transition: all 0.75s ease-in-out;
  flex-wrap: nowrap;
}
.custom-slider-slide {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 100vw;
  height: auto;
}

.video {
  width: 80%;
  height: 80vh;
  margin: 5% auto;
}

.custom-slider-slide-content {
  width: min(1550px, 95%);
}

.top-buttons {
  h2 {
    font-weight: 700;
  }
  display: flex;
  & > button {
    &:last-child {
      margin-left: auto;
    }
  }
}
.top-buttons-rect {
  width: 60px;
  height: 25px;
  top: 3px;
  border-radius: 5px;
  margin-inline-end: 0.5rem;
}
.faq-list {
  list-style: disc;
  margin: unset;
  margin-left: 1rem;
}
.middle-row-left {
  margin-top: 4rem;
}
.sub-heading-rect {
  width: 15px;
  height: 20px;
  border-radius: 5px;
  top: 2px;
  margin-inline-end: 0.5rem;
}
.middle-row-left-sub-heading {
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
}
.middle-row-right {
  padding: 0 6rem 0 3rem;
  position: relative;
  &::after {
    position: absolute;
    background-color: var(--secondary-color);
    width: 90%;
    left: 5%;
    height: 35%;
    content: '';
    top: 25%;
    z-index: -1;
    border-radius: 30px;
  }
}
.middle-row-right-truck-container {
  position: relative;
  img {
    width: 100%;
  }
}
.subImage {
  position: absolute;
  display: block;
  width: 4.5rem !important;
  right: 5%;
  bottom: 0;
}
.middle-row-right-details-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.middle-row-right-details {
  background-color: var(--croonus-white);
  box-shadow: 0 0 10px 10px rgba(30, 30, 30, 0.1);
  border-radius: 10px;
  padding: 2rem 1.5rem;
  font-size: 0.875rem;
  h5 {
    font-size: 1.0625rem;
    font-weight: 600;
    color: var(--primary-color);
  }
  & > span {
    display: block;
  }
  .double-span {
    padding: 0.125rem 0;
    border-top: 1px solid var(--croonus-lightMedGray);
    &:last-child {
      border-bottom: 1px solid var(--croonus-lightMedGray);
    }
    display: flex;
    justify-content: space-between;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      height: 60%;
      top: 20%;
      width: 1px;
      background-color: var(--secondary-color);
    }
  }
  .bolded-span {
    padding: 0.25rem 0;
    font-weight: 600;
  }
}
.double-span-container {
  padding: 0 1rem 0 0;
}
.arrows {
  margin-top: 1rem;
  background-color: var(--primary-color);
  display: flex;
  color: var(--croonus-white);
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 1rem;
  height: 50px;
  border-radius: 10px;
  span {
    font-weight: 500;
    font-size: clamp(1rem, 1.25vw, 1.25rem);
    text-align: center;
  }
  button {
    cursor: pointer;
    img {
      filter: invert(80%) sepia(25%) saturate(4796%) hue-rotate(345deg)
        brightness(99%) contrast(98%);
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      filter: brightness(0) invert(1);
      transition: all 0.2s ease-in-out;
    }
  }
}
.bottom {
  margin-top: 5rem;
}
.bottom-row {
  margin-top: 3rem;
  h2 {
    margin-bottom: 2rem;
  }
}
.bottom-col {
  padding: 0 2rem 0 0;
  .margin-bottom-1rem {
    margin-bottom: 0.5rem;
  }
}
@media (max-width: 1198.98px) {
  .bottom-col-right {
    margin-top: 0;
  }
}
@media (max-width: 920.98px) {
  .container {
    padding-top: 0;
  }
  .middle-row-left {
    margin-top: 0rem;
  }
  .top-buttons {
    flex-wrap: wrap;
    h2 {
      order: 0;
      width: 100%;
      margin-top: 2rem;
    }
    button {
      order: 1;
      height: 50px;
      span {
        padding: 0.5rem !important;
        & > span {
          padding: 0;
        }
      }
    }
  }
  .bottom-col {
    padding: 0;
    button {
      span {
        font-size: 0.75rem;
      }
    }
  }
}
@media (max-width: 768px) {
  .middle-row-right {
    padding: 0 3rem;
  }
  .video {
    height: 30vh;
  }

  .subImage {
    width: 3.5rem !important;
    top: 0;
    left: 0;
  }
}
