.card-preview-modal {
  & > div {
    width: 95% !important;
    max-width: 95% !important;
    @media (min-width: 576px) {
      --bs-modal-width: 95%;
    }
  }
}
.subheading {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  font-size: clamp(0.9375rem, 1.6vw, 1.125rem);
  @media (max-width: 575.98px) {
    margin-bottom: 2rem !important;
  }
}
.input-container {
  margin-bottom: 1rem;
  height: 60px;
  & > div {
    & > div {
      & > div {
        padding: 1rem 0 0.5rem 0;
      }
    }
  }
}
.cloud-paragraph {
  flex: 1;
  padding: 2rem;
  box-shadow: 0 0 15px 1px rgba(160, 160, 160, 0.3);
  border-radius: 10px;
  font-size: 0.9375rem;
  line-height: 1.25rem;
  span {
    display: inline-block;
  }
  @media (max-width: 1399.98px) {
    display: none;
  }
}
.option-button-col {
  display: flex;
  flex-direction: column;
}
.choose-option {
  height: 60px;
  width: 80%;
  margin-bottom: 2rem;
  margin-inline: auto;
  font-weight: 500;
  font-size: 0.9rem;
  text-align: left;
  text-decoration: none;
}
.option-button-container {
  margin-inline: auto;
  --bs-gutter-x: 2.5rem;
}
.body {
  padding: 1rem 6rem 3rem 6rem;
}
.modal-button {
  height: 50px;
  font-weight: 600;
  width: fit-content;
}

.modal-rect {
  top: 4px;
  width: 60px;
  border-radius: 5px;
  margin-inline-end: 0.5rem;
}
.modal-form {
  width: 100%;
  img {
    top: -50px !important;
  }
}
.modal-paragraphs {
  font-size: 0.875rem;
  margin: 2rem 0;
}
.modal-row-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 2rem;
}
.modal-row-right {
  img {
    width: 100%;
  }
}
.modal-arrow {
  padding: 0.25rem 0;
}
.close-button {
  margin: 1rem;
  opacity: 1;
  transform: scale(1.5);
  &:focus {
    box-shadow: none;
  }
}
.back-button {
  height: 60px;
  padding: 0.75rem 0;
  margin-inline-start: 1rem;
  border: none;
  background-color: transparent;
  width: fit-content;
  img {
    height: 100%;
  }
}
@media (max-width: 991.98px) {
  .modal-row-right {
    margin-top: 2rem;
  }
  .body {
    padding: 0 1.5rem 2rem 1.5rem;
  }
}
.float-input-div-col {
  position: relative;
  input[type='date'] {
    background-color: transparent;
    color: var(--croonus-black);
    height: 60px;
  }
  @media (min-width: 1400px) {
    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 3.8125rem;
        height: 60%;
        background-color: var(--secondary-color);
        width: 2px;
      }
    }
  }
  @media (max-width: 1399.98px) {
    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        right: 2rem;
        bottom: 0;
        height: 2px;
        background-color: var(--secondary-color);
        width: calc(100% - 4rem);
      }
    }
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
}
.vehicle-type-row {
  --bs-gutter-x: 4rem;
}
.inputs-row {
  --bs-gutter-x: 4rem;
}
.float-input-div-container {
  margin-bottom: 1.25rem;
  padding: 0 0.5rem;
  height: 60px;
  div {
    height: 100%;
  }
  input {
    height: 100%;
  }
}
.form-subheading {
  margin-bottom: 2rem;
}
.send-request {
  height: 60px;
  margin-inline-start: auto;
  font-weight: 600;
}
.two-inputs {
  & > div {
    &:first-child {
      padding-right: 0.25rem;
    }
    &:last-child {
      padding-left: 0.25rem;
      label {
        padding-left: 0.25rem;
      }
    }
  }
}
