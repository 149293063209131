.body {
    padding: 3rem;
}
.modal-button {
    height:50px;
    font-weight: 600;
    width:fit-content;
}

.modal-rect {
    top: 4px;
    width:60px;
    border-radius: 5px;
    margin-inline-end:0.5rem;

}
.modal-form {
    width:100%;
    img {
        top: -50px !important;
    }
}
.modal-paragraphs {
    font-size:0.875rem;
    margin: 2rem 0;
}
.modal-row-left {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right:2rem;
}
.modal-row-right {
    img {
        width:100%;
    }
}
.modal-arrow {
    padding: 0.25rem 0;
}
.close-button {
    margin: 1rem;
    opacity: 1;
    transform: scale(1.5);
    &:focus {
        box-shadow: none;
    }
}

@media(max-width: 991.98px) {
    .modal-row-right {
        margin-top: 2rem;
    }
    .body {
        padding: 1.5rem;
    }
}