.container {
  h2 {
    font-weight: 700;
    .contact-us-rect {
      width: 60px;
      height: 20px;
      border-radius: 5px;
      margin-inline-end: 0.5rem;
    }
    margin-bottom: 3rem;
  }
  padding: 5rem 0 2rem 0;
  width: min(1550px, 95%);
  margin-inline: auto;
}
.right-side-col {
  padding: 0.5rem 0.375rem;
}
.right-side {
  box-shadow: 0 0 5px 5px rgba(60, 60, 60, 0.1);
  border-radius: 10px;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contact-button {
  height: 50px;
  font-weight: 500;
  width: fit-content;
  margin-inline-start: auto;
  margin-inline-end: 0.25rem;
  .contact-button-arrow {
    padding: 0.25rem 0;
  }
}
.left-side {
  padding: 3rem 0;
  display: flex;
}
.left-side-content {
  background-color: var(--primary-color);
  color: var(--croonus-white);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  h4 {
    font-weight: 650;
    font-size: 1.25rem;
    margin: 1rem 0 0 0;
  }
  .city-rect {
    height: 18px;
    width: 13px;
    border-radius: 5px;
    top: 1px;
    margin-inline-end: 0.5rem;
  }
  p {
    font-size: 0.875rem;
    span {
      display: inline-block;
    }
    padding-left: 1.375rem;
  }
}
.map-container {
  margin: 8rem 0 1rem 0;
  height: 600px;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  border: 5px solid var(--secondary-color);
  .mapRel {
    position: absolute;
    top: -200px;
  }
}

.footer-top-left-heading {
  width: 100%;
  font-size: 1.25rem;
  font-weight: 650;
}
.footer-rect {
  top: 2px;
  width: 30px;
  height: 20px;
  border-radius: 5px;
  margin-inline-end: 0.5rem;
}

.map-container-cloud {
  position: absolute;
  border-radius: 10px;
  box-shadow: 0 0 10px 10px rgba(60, 60, 06, 0.1);
  padding: 1.5rem 2rem;
  background-color: var(--croonus-white);
  top: 255px;
  left: 500px;
  h2 {
    color: var(--primary-color);
    span {
      width: 15px;
    }
  }
  p {
    margin: unset;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    span {
      display: block;
    }
  }
  &::before {
    content: "";
    background-color: var(--croonus-white);
    position: absolute;
    width: 35px;
    height: 35px;
    top: 50%;
    right: 0;
    transform: translateX(30%) translateY(-50%) rotate(45deg);
    border-radius: 10px;
  }
}

@media (max-width: 991.98px) {
  .left-side {
    justify-content: center;
    padding-bottom: 0;
    .left-side-content {
      border-bottom-left-radius: 0px;
      border-top-right-radius: 10px;
    }
  }
  .container {
    h2 {
      margin-bottom: 0;
    }
  }
}
@media (max-width: 767.98px) {
  .right-side {
    padding: 5rem 0.5rem;
    button {
      margin-inline-start: 0.375rem;
    }
  }
}
