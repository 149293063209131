.top-margin-1 {
  margin-top: 1rem;
}
.product-details-slider-container {
  margin: unset;
  padding: unset;
  margin-top: 5rem;
  max-width: 100%;
}
.product-details-slider-container .row {
  justify-content: space-around;
}
.slick-small-img {
  width: 100%;
}
.slick-small-img-div {
  width: 100%;
  position: relative;
  .test {
    height: 100%;
    width: 100%;
    top: 0;
    position: absolute;
  }
  overflow: hidden;
  border-radius: 10px;
}
.slick-slider {
  position: relative;
  &:hover {
    .prevArrow,
    .nextArrow {
      display: flex !important;
    }
  }
  .nextArrow {
    height: 100%;
    right: 20px;
  }
  .prevArrow {
    height: 100%;
    left: 20px;
  }
}
.arrow {
  z-index: 1;
  position: absolute;
  justify-content: center;
  color: var(--croonus-mediumGray);
  width: 80px;
  transition: all 0.25s ease-in-out;
  &:hover {
    color: var(--croonus-white);
    transition: all 0.15s ease-in-out;
  }
  .slick-next-arrow {
    height: 20px;
  }
  .slick-prev-arrow {
    height: 20px;
  }
}
.slick-list {
  height: fit-content;
}
.vertical-slider {
  .arrow {
    position: absolute;
    left: 0;
    bottom: -84px;

    display: flex;

    align-items: center;
    height: 60px;
    background-color: var(--primary-color);
    width: 100%;
  }
  .prevArrow {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 50%;
    left: 0;
    img {
      transform: translateX(-100%) rotate(180deg);
      height:40px;
      transition: all 0.3 ease-in-out;
    }
    &:hover {
      img {
        filter: brightness(0) invert(1);
        transition: all 0.2s ease-in-out;
      }
    }
  }
  .nextArrow {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 50%;
    left: 50%;
    img {
      transform: translateX(100%);
      height:40px;
      transition: all 0.3 ease-in-out;
    }
    &:hover {
      img {
        filter: brightness(0) invert(1);
        transition: all 0.2s ease-in-out;
      }
    }
  }
  position: relative;

  .slick-current {
    img {
      border-bottom: 2px solid var(--croonus-black);
    }
  }
  // .slick-active:hover {
  //   position: relative;
  //   &::after {
  //     content: "";
  //     position: absolute;
  //     height: 98%;
  //     width: 98%;
  //     left: 1%;
  //     top: 1%;
  //     // border:1px solid var(--croonus-lightMedGray);
  //   }
  // }
  .slick-track {
    height: unset !important;
  }
}
.slick-flex-down {
  display: flex;
  position: relative;
  align-items: center;
}
.big-slick-slider {
  .slick-list {
    width: 100%;
  }
  display: flex;
  .arrow {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  position: relative;
  // &::after {
  //   content: '';
  //   position: absolute;
  //   height: 100%;
  //   width:100%;
  //   left:0;
  //   top:0;
  //   border:1px solid var(--croonus-lightMedGray);
  // }
}
/* the slides */
.slick-slide {
  margin: 1px 0;
}

/* the parent */
.slick-list {
  margin: -1px 0;
}
.slick-flex-down {
  justify-content: flex-end;
  align-items: flex-start;
}
.slick-arrow-circle {
  img {
    filter: invert(80%) sepia(25%) saturate(4796%) hue-rotate(345deg)
      brightness(99%) contrast(98%);
  }
}

@media (max-width: 1198.98px) {
  .slick-flex-down {
    justify-content: center;
  }
  .slick-small-img-div {
    padding:0 0.25rem;
    img {
      border-radius: 10px;
    }
  }
}

@media (min-width: 768px) {
  .vertical-slider {
    //top:-5rem;
  }
}

@media (max-width: 767.98px) {
  .slick-flex-down {
    justify-content: center;
    order: 1;
  }
}
