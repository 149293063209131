.container {
  padding: 5rem 0 2rem 0;
  width: min(1550px, 95%);
  margin-inline: auto;
}
.middle {
  padding: 0 4rem;
  @media (max-width: 1199.98px) {
    padding: unset;
  }
}
.middle-content {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: var(--primary-color);
  color: white;
  padding: 1.5rem;
  h2 {
    font-weight: 700;
    .about-us-rect {
      width: 60px;
      height: 20px;
      border-radius: 5px;
      margin-inline-end: 0.5rem;
    }
  }
  p {
    margin: 3rem 0;
    font-style: italic;
    font-weight: 500;
  }
  .owners {
    margin-bottom: 3rem;
    display: block;
    padding-right: 9rem;
    font-weight: 500;
    font-style: italic;
    span {
      color: var(--secondary-color);
    }
  }
  img {
    width: min(400px, 100%);
    margin-inline: auto;
    margin-bottom: 2rem;
  }
}
.bottom-text {
  margin-top: 5rem;
}
.bottom-text-left {
  padding-right: 2rem;
}
.bottom-text-right {
  padding-left: 2rem;
}
.small-heading {
  font-weight: 600;
  font-size: 1.5rem;
}
.about-rect-small {
  height: 20px;
  top: 1px;
  margin-inline-end: 0.5rem;
  width: 15px;
  border-radius: 3px;
}
.paragraph {
  ul {
    list-style: disc;
    margin-left: 1rem;
  }
}
.small-sub-heading {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}
.margined-list {
  margin-left: 1.125rem !important;
  font-size: 1rem;
}
@media (max-width: 1199.98px) {
  .container {
    & > div {
      order: 1;
      margin: 2rem 0;
    }

    .middle {
      order: 0;
    }
  }
  .not-middle {
    padding: 0 1.5rem;
  }
}
@media (max-width: 992.98px) {
  .middle {
    padding: 0 0rem;
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
