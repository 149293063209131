.container {
    padding: 5rem 0 1rem 0;
    width:min(1550px, 95%);
    margin-inline: auto;
}

.posts-row {
    margin-top: 5rem;
}
.posts-col {
    padding: 0 1rem;
}

.blog-rect {
    width:60px;
    height:25px;
    top:1px;
    border-radius: 5px;
    margin-inline-end: 0.5rem;
}