.container {
  border-radius: 10px;
  
  box-shadow: 0 0 1px 1px var(--croonus-lightMedGray);
  padding: 2rem 2rem 0rem 2rem;
  height: 400px;
  width: 100%;
  transition: all 0.3s ease-in-out;
  position: relative;
  img {
    height: 50px;
  }
  h4 {
    font-size: 1.25rem;
    font-weight: 700;
    margin: 2rem 0 1rem 0;
    line-height: 1.75rem;
  }
  p {
    margin: 2rem 0 0 0;
    font-weight: 450;
    font-size: 0.9125rem;
  }
  .dot-border {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    position: absolute;
    z-index: 2;
    background-color: transparent;
    width: calc(100% + 35px);
    height: calc(100% + 40px);
    top: 0;
    left: 0;
  }
  &:hover {
    box-shadow: 0 0 10px 5px rgba(60, 60, 60, 0.1);
    transition: all 0.2s ease-in-out;
    .dot-border {
      opacity: 1;
      transition: all 0.2s ease-in-out;
    }
  }
}

@media (max-width: 991.98px) {
  .dot-border {
    display:none;
  }
  .container {
    padding: 2rem 1rem;
    h4 {
      margin: 0.5rem 0 ;
    }
    p {
      margin-top: 0.5rem;
    }
  height: 300px;
  }
}