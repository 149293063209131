.container {
    overflow: hidden;
    padding: 0.5rem 0.5rem;
    display:flex;
    align-items: center;
    justify-content: center;
    height:150px;
    margin: 1rem 0;
    width: 260px;
    border: 1px solid var(--croonus-mediumGray);
    background-color: var(--croonus-white);
    border-radius: 10px;
    img {
        max-height: 100%;
        max-width:100%;
        transform: scale(1);
        filter: brightness(0);
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        img {
            transform: scale(1.05);
            transition: all 0.2s ease-in-out;
            filter: unset;
        }
        
    }
    @media(max-width:575.98px) {
        height:100px !important;
    }
}