.cookie-pop {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  padding: 1rem 0 1rem 0;
  background-color: white;
  color: var(--croonus-black);
  box-shadow: 0 0 3px 2px rgba(3, 3, 3, 0.3);
}
.container {
  width: min(650px, 95%);
  margin-inline: auto;
}
.heading-button {
  display: flex;
  align-items: flex-end;
  h6 {
    font-weight: 600;
    margin: unset;
  }
  button {
    border: none;
    background-color: transparent;
    // color: white;
    display: flex;
    font-size: 0.875rem;
    line-height: 0.9375rem;
    img {
      height: 15px;
      margin-inline-start: 1rem;
      margin-inline-end: 0.5rem;
      filter: brightness(0);
    }
    .arrow-up {
      transform: rotate(180deg);
    }
  }
}
.accept {
  border: none;
  background-color: var(--primary-color);
  color: white;
  border-radius: 10px;
  font-weight: 500;
  margin-top: 1rem;
  padding: 0.125rem 1rem;
}
.show-more {
  a {
    color: black;
    font-weight: 500;
  }
  p {
    margin: unset;
  }
  margin-top: 1rem;
  .more-info {
    margin-top: 1rem;
    display: block;
    background-color: white;
    width: fit-content;
    padding: 0.125rem 0.5rem;
    border: 1px solid var(--croonus-mediumGray);
  }
}

.checkboxes-container {
  display: flex;
  margin-top: 1rem;
  align-items: center;
}
.checkbox-div {
  display: flex;
  margin-inline-end: 1rem;
  input {
    accent-color: var(--secondary-color);
  }
  label {
    margin-left: 0.25rem;
  }
}
