.container {
  padding: 5rem 0 1rem 0;
  width: min(1550px, 95%);
  margin-inline: auto;
}
.top-buttons {
  h2 {
    font-weight: 700;
    margin-bottom: unset;
  }
  display: flex;
  align-items: center;
  & > button {
    width: 260px;
    &:last-child {
      margin-left: auto;
    }
    span {
      font-size: 0.9rem !important;
    }
  }
}
.top-buttons-rect {
  width: 60px;
  height: 25px;
  top: 3px;
  border-radius: 5px;
  margin-inline-end: 0.5rem;
}

.paragraphs {
  margin-top: 5rem;
}

@media (max-width: 920.98px) {
  .top-buttons {
    flex-wrap: wrap;

    h2 {
      order: 0;
      width: 100%;
      margin-top: 2rem;
    }
    button {
      width: unset;
      order: 1 !important;
      background: transparent !important;
      padding: 0;
      span {
        padding: 0.5rem !important;
        height: 75% !important;
        & > span {
          padding: 0;
        }
      }
    }
  }
}
