
.container {
  margin: 0rem 0;
  margin-bottom: 1rem;
  padding: 0rem 0rem 0 0rem;
}
.btn-accordion {
  background-color: transparent;
  border: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
}
.btn-accordion {
    font-size: 1.5rem;
}
.card {
  border: none;
  background-color: transparent;
}
.card-header {
  background-color: transparent;
  border: none;
  padding: unset;
  button {
    padding: unset;
    color:var(--croonus-black);
  }
}
.read-more-arrow {

  padding: 0.25rem 0;
}
.card-body {
  border: 1px solid var(--secondary-color);
  border-radius: 10px;
  margin: 2rem 0 1rem 0;
  li {
    font-size: 1rem;
  }
  padding: 1rem 2rem;
  p {
    margin: unset;
  }
}

.read-more {
  height:60px;
  text-align: left;
  font-size: 1rem;
  font-weight: 600;
  width:100%;
}